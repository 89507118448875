import * as env from '$env/static/public';
import type { S3BucketEndpoints } from '$lib/aws/s3/s3_utils';

 // NOTE: Client-side cannot access 'process.env' as this is exclusively server-side. 
 //       If any server-side code needs to access any fields defined here, they need to be
 //       re-exposed in EnvServerSide.
class EnvClientSide {

    public app_hostname(): string {
        return env.PUBLIC_SWIMERIZE_HOSTNAME!;
    }

    public map_token(): string {
        return env.PUBLIC_SWIMERIZE_MAP_TOKEN!;
    }

    public s3_original_uploads_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_ORIGINAL_UPLOADS_S3_CLIENTSIDE_URL!;
    }

    public s3_tracks_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_TRACKS_S3_CLIENTSIDE_URL!;
    }

    public s3_pools_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_POOLS_S3_CLIENTSIDE_URL!;
    }

    public s3_pictures_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_PICTURES_S3_CLIENTSIDE_URL!;
    }
    
    public s3_systempictures_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_SYSTEM_PICTURES_S3_CLIENTSIDE_URL!;
    }

    public s3_heartrates_endpoint(): string {
        return env.PUBLIC_SWIMERIZE_HEART_RATES_S3_CLIENTSIDE_URL!;
    }

    public s3_endpoints(): S3BucketEndpoints {
        return {
            'originaluploads': this.s3_original_uploads_endpoint(),
            'tracks': this.s3_tracks_endpoint(),
            'pools': this.s3_pools_endpoint(),
            'userpictures': this.s3_pictures_endpoint(),
            'systempictures': this.s3_systempictures_endpoint(),
            'heartrates': this.s3_heartrates_endpoint()
        };
    }
    /*
     * End of duplicate config.
     */


    public www_endpoint(): string {
        return `https://${this.app_hostname()}`;
    }

    public is_dev(): boolean {
        return env.PUBLIC_ENV_MODE === 'development';
    }

    public is_ssr_enabled(): boolean {
        return (env.PUBLIC_SWIMERIZE_DISABLE_SSR ?? 'false') === 'false';
    }

    public sentry_dns(): string {
        return env.PUBLIC_SWIMERIZE_SENTRY_DNS;        
    }

    /**
     * Used for the app's custom URL scheme (iOS).
     */
    public app_url_scheme(): string {
        return env.PUBLIC_AUTH_REDIRECT_URL_SCHEME;
    }

    public subscription_products(): { monthly: string, yearly: string } {

        return {
            monthly: env.PUBLIC_APPLE_APPSTORE_SUBSCRIPTION_PRODUCT_ID_MONTHLY,
            yearly: env.PUBLIC_APPLE_APPSTORE_SUBSCRIPTION_PRODUCT_ID_YEARLY            
        };
    }
}

const env_client = new EnvClientSide();
export default env_client;